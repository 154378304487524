<template>
  <v-main>
    <v-container class="mt-15">
      <v-row>
        <v-col cols="8" offset="2">
          <div class="d-flex align-end justify-center mb-8">
            <v-img
              contain
              src="../assets/logoTalana.png"
              max-width="110px"
              class="mb-2 d-inline-block"
            ></v-img>
            <v-divider
              vertical
              class="mx-5 mt-4"
              style="height: 22px; border-color: #aeaeae"
            />
            <span class="font-weight-medium" style="font-size: 20px"
              >Ficha Integral de Cliente</span
            >
          </div>
          <v-autocomplete
            @focus="focus=true"
            @blur="unfocusInput"
            :menu-props="{overflowY: true}"
            @input="getTokenBySearch"
            v-model="search"
            :items="search_results"
            item-color="indigo"
            item-text="nombre"
            item-value="id"
            v-debounce:200="searchCompany"
            :search-input.sync="search_input"
            hide-no-data
            hide-details
            clearable
            :solo="focus"
            dense
            rounded
            :shaped="focus&&search_results.length!=0"
            background-color="white"
            style="width: 400px"
            height="50px"
            placeholder="Buscar empresa"
            class="mx-auto search_input_index"
            :append-icon="this.search_input==null ? 'mdi-magnify' : null"
            color="#2222BF">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    search: null,
    search_input: null,
    focus: false
  }),
  methods: {
    searchCompany(){
      if(this.search_input){
        this.getCompaniesBySearch(this.search_input)
      }
    },
    getTokenBySearch(){
      if(this.search){
        this.getToken(this.search)
      }
    },
    unfocusInput(){
      this.focus = false
      this.resetSearchItems()
    },
    ...mapActions(['getCompaniesBySearch','getToken','resetSearchItems'])
  },
  computed: mapState(['search_results'])
}
</script>

<style lang="scss">
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
.v-menu__content{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 5px 0px rgb(0 0 0 / 12%)
}
.search_input_index .v-input__control .v-input__slot .v-icon{
  margin-top: 4px!important
}
</style>
