<template>
  <div>
    <v-tabs
    background-color="transparent"
    centered
    center-active
    show-arrows
  >
    <v-tab
      v-for="(tab,i) in tabs"
      :key="i"
      :to="tab.link"
      class="text-capitalize tabs px-6"
      :disabled="tab.disabled"
    >
      {{ tab.title }}
    </v-tab>
  </v-tabs>
  <v-divider class="mb-2"></v-divider>
  </div>
</template>

<script>
export default {
  data: () => ({
    tabs: [
      { title: 'Resumen', link: '/resumen', disabled: false},
      { title: 'Comercial', link: '/comercial', disabled: false},
      // { title: 'Implementación', link: '/implementacion', disabled: true},
      { title: 'Finanzas', link: '/finanzas', disabled: false},
      { title: 'Bitácora', link: '/bitacora', disabled: false},
    ]
  })

}
</script>

<style>
a.tabs{
  letter-spacing: normal;
  text-align: center;
  color: #181818;
  font-size: 16px;
}

.v-tabs-bar__content a.v-tab:not(.v-tab--active){
  color: #181818!important;
}

.v-tabs-bar__content a.v-tab--active, .v-tabs-slider-wrapper .v-tabs-slider{
  color:#2222BF!important;
  font-weight: 700;
}

.v-tabs-bar__content  div.v-tabs-slider-wrapper{
  height: 5px!important;
}
</style>
