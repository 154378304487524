<template>
  <div>
    <app-bar/>
    <p class="text-center mb-2 mt-6 font-weight-bold" style="font-size: 22px" v-if="company">
      {{company.nombre}}
      <v-chip v-if="company.bitacora_status" :color="company.bitacora_status.color" text-color="white" x-small class="ml-1 px-2" style="font-size: 11px">
        {{company.bitacora_status.name}}
      </v-chip>
    </p>
    <v-container>
      <content-tabs></content-tabs>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import AppBar from './AppBar';
import ContentTabs from './ContentTabs'

export default {
  components: {
    AppBar,
    ContentTabs
  },
  computed: mapState(['company'])
}
</script>
