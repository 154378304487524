<template>
  <div>
    <v-card flat class="pa-3 mb-3" v-for="(log, i) in logs.results" :key="i" @click="openDetail(log)">
      <v-row no-gutters>
        <v-col cols="11" md="10">
          <div class="d-flex">
            <v-avatar size="36" :color="log.author.personadetalle_set.length>0 && log.author.personadetalle_set[0].foto ? '': '#2222BF'">
              <v-img :src="log.author.personadetalle_set[0].foto" v-if="log.author.personadetalle_set.length>0 && log.author.personadetalle_set[0].foto"></v-img>
              <span class="white--text" v-else>{{log.author.nombre[0]}}{{log.author.apellidoPaterno[0]}}</span>
            </v-avatar>
            <div class="ml-3">
              <p class="mb-0" style="font-size: 12px; color: #757575">
                <v-chip x-small :color="log.status.color" text-color="white" v-if="log.status" class="mr-3 px-2 mb-1 hidden-sm-and-down" style="font-size: 11px">{{log.status.name}}</v-chip>Publicado el {{ log.created | moment("DD/MM/YYYY, kk:mm")}} <span class="ml-4 hidden-sm-and-down" style="font-size: 11px; color: #2222BF;">{{capitalizeFirstLetter(log.category.name)}}</span>
              </p>
              <p class="mb-0 font-weight-medium" style="font-size: 13px">
                {{log.author.nombre}} {{log.author.apellidoPaterno}} {{log.author.apellidoMaterno}}
              </p>
              <div class="hidden-md-and-up mt-1 mb-2">
                <p class="mb-0" style="font-size: 10px; color: #2222BF;"><v-chip x-small :color="log.status.color" text-color="white" v-if="log.status" class="mr-2 px-2 mb-1">{{log.status.name}}</v-chip>{{capitalizeFirstLetter(log.category.name)}} <v-icon small v-if="log.files.length>0" class="ml-2 mb-1">mdi-attachment</v-icon></p>
              </div>
              <p class="font-weight-bold mt-2 mb-0" style="font-size: 15px;">
                {{capitalizeFirstLetter(log.title)}} <v-icon size="20px" v-if="log.files.length>0" class="ml-2 hidden-sm-and-down">mdi-attachment</v-icon>
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="1" md="2">
          <v-btn icon absolute right bottom color="#757575" @click="openDetail(log)">
            <v-icon>mdi-arrow-expand</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <results-pagination :count="logs.count" :getByPage="getLogsByPage" class="my-10" v-if="logs.count>10"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ResultsPagination from '../App/ResultsPagination'

export default {
  components: {
    ResultsPagination
  },
  methods: {
    openDetail(log){
      this.openDetailLog(log)
    },
    ...mapActions(['openDetailLog','getLogsByPage'])
  },
  computed: {
    ...mapState(['logs']),
    ...mapGetters(['capitalizeFirstLetter'])
  },
};
</script>

<style lang="scss" scoped>
.v-card--link:before{
  background: inherit
}
</style>
