<template>
  <v-main>
    <v-card flat class="pa-8 text-center">
      <v-icon size="144" color="#CCCCCC">mdi-notebook-edit-outline</v-icon>
      <p class="mt-5 mb-1 font-weight-bold" style="font-size: 18px">Aún no hay notas creadas.</p>
      <p style="color: #747474; font-size: 14px">Puedes registrar cualquier tipo de cambio que le sea útil al resto del equipo.</p>
      <v-btn @click="openNotePad" small outlined color="#2222BF" class="text-none font-weight-regular letter_spacing px-2 mt-1" style="font-size: 14px">
        <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
        Nueva nota
      </v-btn>
    </v-card>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: mapActions(['openNotePad'])
}
</script>
