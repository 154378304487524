<template>
  <v-main>
    <div class="text-right mb-4" v-if="logs.count>0">
      <v-btn @click="openNotePad" small outlined color="#2222BF" class="text-none font-weight-regular letter_spacing px-2" style="font-size: 14px">
        <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
        Nueva nota
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12" md="3">
        <category-list></category-list>
      </v-col>
      <v-col cols="12" md="9">
        <log-results v-if="logs.count>0"></log-results>
        <no-log-results v-else/>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CategoryList from '../components/ClientLog/CategoryList'
import LogResults from '../components/ClientLog/LogResults'
import NoLogResults from '../components/ClientLog/NoLogResults'

export default {
  components: {
    CategoryList,
    LogResults,
    NoLogResults
  },
  methods: {
    getToken(){
      let token = this.$route.query.token
      this.saveToken(token)
    },
    ...mapActions(['openNotePad','saveToken','getLogs','getCompanyInfo'])
  },
  computed: mapState(['logs']),
  mounted(){
    if(this.$route.path == '/bitacora-form'){
      this.getToken()
      this.openNotePad()
      this.$router.replace('/bitacora')
    }
    this.getLogs()
  },
};
</script>
