<template>
  <v-main>
    <company-info/>
    <v-container>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-container>
  </v-main>
</template>

<script>
import CompanyInfo from '../components/App/CompanyInfo'

export default {
  components: {
    CompanyInfo
  },
}
</script>
