<template>
  <v-card flat class="py-2">
    <v-list dense class="px-0" v-if="logs.count>0">
      <v-subheader class="ml-3 font-weight-medium" style="color: #757575">Categorías</v-subheader>
      <v-list-item-group
        active-class="active_category"
        @change="getFilteredLogs"
        v-model="selectedItem"
      >
        <v-list-item dense v-for="(item, i) in computedItems" :key="i" :value="item.id" :disabled="item.id == selectedItem">
          <v-list-item-content class="px-1">
            <v-row no-gutters>
              <v-col cols="9" lg="10">
                <v-list-item-title style="font-size: 14px">{{
                  item.name
                }}</v-list-item-title>
              </v-col>
              <v-col cols="3" lg="2" class="text-right text-md-center" align-self="end">
                <p class="mb-0 font-weight-medium" style="font-size: 14px">{{formattedNumber(item.total_logs)}}</p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else class="text-center px-6 py-15">
      <p style="color: #757575; font-size: 14px" class="mb-0">Las categorías aparecerán cuando existan notas creadas.</p>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  data: () => ({
    selectedItem: 0,
    all_logs: 0
  }),
  methods: {
    getAllLogs(){
      this.all_logs = null
      this.categories.forEach(cat => this.all_logs+= cat.total_logs);
    },
    getFilteredLogs(){
      this.getByCategory(this.selectedItem)
    },
    ...mapActions(['getByCategory'])
  },
  computed: {
    ...mapState(['categories','logs']),
    ...mapGetters(['formattedNumber']),
    computedItems(){
      this.getAllLogs()
      let total_logs = { name: "Todas", id: 0, total_logs: this.all_logs }
      let all_cat = [ total_logs, ...this.categories]
      return all_cat.filter(c => c.total_logs > 0 )
    },
  }
};
</script>

<style lang="scss">
.active_category {
  background: #2222bf;
  transition: all 0.1s ease-in;

  .v-list-item__title, p{
      color: white;
  }
}
</style>
