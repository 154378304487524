<template>
  <div class="text-center">
    <v-pagination
      :value="page"
      :length="Math.ceil(count/10)"
      @input="getPage"
      color="#2222BF"
    ></v-pagination>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: ['count', 'getByPage'],
  computed: mapState(['page']),
  methods: {
    getPage(p){
      this.updatePage(p)
      this.getByPage(p)
    },
    ...mapActions(['updatePage'])
  }
}
</script>

<style lang="scss">
li .v-pagination__navigation, li .v-pagination__item, li .v-pagination__item--active{
  box-shadow: none;
}
</style>
