<template>
  <div>
    <v-app-bar color="white" flat>
      <v-container class="d-flex align-center pl-2 pr-0 px-md-3">
          <div class="d-flex align-end">
            <v-img contain src="../../assets/logoTalana.png" max-width="110px" class="mb-2 hidden-sm-and-down"></v-img>
            <v-img contain src="../../assets/globoTalana.png" max-width="25px" class="mb-2 hidden-md-and-up"></v-img>
            <v-divider vertical class="mx-5 mt-4 hidden-sm-and-down" style="height: 22px; border-color: #AEAEAE"/>
            <span class="font-weight-medium hidden-sm-and-down" style="font-size: 20px">Ficha Integral de Cliente</span>
          </div>
          <v-spacer></v-spacer>
          <div id="search">
            <v-autocomplete
              @focus="focus=true"
              @blur="unfocusInput"
              :menu-props="{overflowY: true}"
              @input="getTokenBySearch"
              v-model="search"
              :items="search_results"
              item-color="indigo"
              item-text="nombre"
              item-value="id"
              v-debounce:200="searchCompany"
              :search-input.sync="search_input"
              hide-no-data
              hide-details
              clearable
              :filled="!focus"
              :solo="focus"
              dense
              rounded
              :shaped="focus&&search_results.length!=0"
              style="width: 450px"
              height="35px"
              placeholder="Buscar empresa"
              :append-icon="this.search_input==null ? 'mdi-magnify' : null"
              color="#2222BF">
              <template v-slot:item="data">
                <template v-if="!data.item.razones_sociales || data.item.razones_sociales.length==0">
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 14px" class="font-weight-medium">{{data.item.nombre}}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 14px" class="font-weight-medium">{{data.item.nombre}}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px; color: #181818" class="font-weight-regular" v-for="rs in data.item.razones_sociales" :key="rs.id">Razón social {{rs.razonSocial}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </div>
      </v-container>
    </v-app-bar>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    search: null,
    search_input: null,
    focus: false
  }),
  methods: {
    searchCompany(){
      if(this.search_input){
        this.getCompaniesBySearch(this.search_input)
      }
    },
    getTokenBySearch(){
      if(this.search){
        this.getToken(this.search)
        this.unfocusInput()
      }
    },
    unfocusInput(){
      this.focus = false
      this.resetSearchItems()
    },
    ...mapActions(['getCompaniesBySearch','getToken','resetSearchItems'])
  },
  computed: mapState(['search_results'])
}
</script>

<style lang="scss">
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
.v-menu__content{
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 3px 2px 0px rgb(0 0 0 / 14%), 0px 3px 5px 0px rgb(0 0 0 / 12%)
}
</style>
